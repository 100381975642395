import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Select,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import {
  Conjunction,
  FilterInputGrouped,
  GetFiltersQueryQuery,
  GetFiltersQueryQueryVariables,
  Maybe,
} from '../../generated/types';
import {
  entityFilterConditionToQueryString,
  filterFieldToHuman,
  filterInputGroupedToQueryString,
} from '../../utils/parseFilterQueryString';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { GET_FILTERS_QUERY } from '../../queries';
import { useLazyQuery } from '@apollo/client';
import { useStore } from '../../store/provider';

interface FilterDrawerProps {
  open: boolean;
  onClose: () => void;
  category: string;
  activeFilters?: Maybe<{
    label: string;
    field: string;
    value: string;
  }>[];
}

interface FilterFormValues {
  country_tid?: string;
  region_tid?: string;
  postcode_ref_tid?: string;
  city_ref_tid?: string;
  features?: { id: string | boolean }[];
  group_search?: boolean;
}

export default function FilterDrawer({
  open,
  onClose: onCloseParent,
  category,
  activeFilters,
}: FilterDrawerProps) {
  const router = useRouter();
  const store = useStore();
  const [getLazyData, { data, loading }] = useLazyQuery<
    GetFiltersQueryQuery,
    GetFiltersQueryQueryVariables
  >(GET_FILTERS_QUERY);

  /**
   * TODO: There seems to be a problem with remounting and resetting values.
   */

  const initialValues = useMemo(() => {
    // TODO: handle multi regions/countries ? currently the field is just overwritten by
    //  last value and we have no multiselect
    const initialValuesFromActiveFilters = activeFilters?.reduce(
      (prev, curr) => {
        if (curr) {
          prev[curr.field] = curr.value;
        }

        return prev;
      },
      {}
    );

    return initialValuesFromActiveFilters;
  }, [activeFilters]);

  const reload = () => {
    getLazyData({
      variables: {
        frontend: store.config.frontendName,
        category: category,
        filter: {
          conditions: [],
          groups: [],
          scopes: [],
          conjunction: Conjunction.And,
        },
      },
    });
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    setError,
    getValues,
  } = useForm<FilterFormValues>({
    defaultValues: initialValues,
  });

  useEffect(() => {
    register('postcode_ref_tid');
    register('city_ref_tid');
  }, [register]);

  const postcodeValue = watch('postcode_ref_tid');
  const cityValue = watch('city_ref_tid');
  const coutryValue = watch('country_tid');

  const currentFilters = useMemo(() => {
    const filters: Array<{
      field: string;
      label: string;
    }> = [];

    activeFilters?.forEach((filter) => {
      if (filter) {
        if (filter.field === 'city_ref_tid') {
          if (filter.value == cityValue) {
            filters.push({
              field: filter.field,
              label: filter.label,
            });
          }
        } else if (filter.field === 'postcode_ref_tid') {
          if (filter.value == postcodeValue) {
            filters.push({
              field: filter.field,
              label: filter.label,
            });
          }
        }
      }
    });

    return filters;
  }, [activeFilters, postcodeValue, cityValue]);

  useEffect(() => {
    if (open) {
      reload();
      resetToInitial();
    }
  }, [open]);

  useEffect(() => {
    if (data && !loading) {
      resetToInitial();
    }
  }, [data, loading]);

  // const removeFilter = (activeFilter: ActiveFilter) => () => {
  //   if (router.query && router.query.filter) {
  //     let filterQueries = [];
  //     if (Array.isArray(router.query.filter)) {
  //       filterQueries = router.query.filter.map((filter) =>
  //         parseFilterQueryString(filter)
  //       );
  //     } else {
  //       filterQueries = [parseFilterQueryString(router.query.filter)];
  //     }

  //     const newFilters: Array<string> = filterQueries
  //       .filter((filter): filter is EntityFilter => {
  //         if (!filter) return false;

  //         return filter.field !== activeFilter.field;
  //       })
  //       .flatMap((f) => {
  //         const string = entityFilterConditionToQueryString(f);

  //         if (!string) return [];

  //         return [string];
  //       });

  //     const newQueryParams = {
  //       ...router.query,
  //       filter: newFilters,
  //     };

  //     const newQuery = qs.stringify(newQueryParams);

  //     router.push({
  //       pathname: router.pathname,
  //       query: newQuery,
  //     });
  //   }
  // };

  const onSubmit = (values: FilterFormValues) => {
    const filters: string[] = [];
    const groups: string[] = [];

    Object.keys(values).forEach((key) => {
      if (values[key] && key !== 'features') {
        const value = Array.isArray(values[key])
          ? values[key].filter((item) => item.id).map((item) => item.id)
          : values[key];

        const filterString = entityFilterConditionToQueryString({
          field: key,
          value,
          operator: Array.isArray(values[key]) ? 'IN' : '=',
        });

        if (filterString) {
          filters.push(filterString);
        }
      }
    });

    if (values.features) {
      const filteredFeatures: string[] = values.features
        .filter((item) => Boolean(item.id))
        .map((item) => item.id) as string[];
      const joinedIds = filteredFeatures.join(',');

      if (joinedIds.length) {
        const group: FilterInputGrouped = {
          conjunction: Conjunction.Or,
          conditions: [
            { field: 'feature_tid', operator: 'IN', value: joinedIds },
            {
              field: 'highlighted_feature_tid',
              operator: 'IN',
              value: joinedIds,
            },
          ],
        };

        groups.push(filterInputGroupedToQueryString(group));
      }
    }

    const filterObj = filters.length ? { filter: filters } : {};
    const groupObj = groups.length ? { groups: groups } : {};

    onClose();

    // // Build the url
    router.push({
      pathname: router.pathname,
      query: {
        path: router.query.path,
        query: router.query.query,
        ...filterObj,
        ...groupObj,
      },
    });
  };

  const resetToInitial = () => {
    if (initialValues) {
      resetFull(initialValues);
    }
  };

  const resetFull = (data: any = {}) => {
    reset({
      postcode_ref_tid: '',
      city_ref_tid: '',
      country_tid: '',
      region_tid: '',
      ...data,
    });
  };

  const onClose = () => {
    resetToInitial();
    onCloseParent();
  };

  return (
    <Drawer isOpen={open} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent
        minWidth={{
          base: '90%',
          md: '500px',
        }}
      >
        <DrawerCloseButton />
        <DrawerHeader>Suche verfeinern</DrawerHeader>

        <DrawerBody position="relative">
          {loading ? (
            <Flex
              position="absolute"
              top="0"
              bottom="0"
              right="0"
              left="0"
              bg="rgba(255,255,255, .8)"
              zIndex={3}
              justifyContent="center"
            >
              <Spinner />
              <Box fontWeight="bold" marginLeft={2}>
                Lädt Daten...
              </Box>
            </Flex>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack>
                <FormControl>
                  <FormLabel>Land</FormLabel>
                  <Select name="country_tid" {...register('country_tid')}>
                    <option value="">- Land wählen -</option>
                    {data?.filters?.countries.items.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Region</FormLabel>
                  <Select name="region_tid" {...register('region_tid')}>
                    <option value="">- Region wählen -</option>
                    {data?.filters?.regions.items.map((region) => {
                      if (coutryValue && region.country) {
                        if (coutryValue != region.country.id + '') {
                          return null;
                        }
                      }

                      return (
                        <option key={region.id} value={region.id}>
                          {region.name}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
                {data?.filters?.features?.items?.length > 0 && (
                  <FormControl>
                    <FormLabel>Merkmale</FormLabel>
                    {data?.filters?.features.items.map((item, i) => (
                      <Box key={item.id}>
                        <Checkbox
                          name={`features.${i}.id`}
                          value={item.id}
                          {...register(`features.${i}.id`)}
                        >
                          {item.name}
                        </Checkbox>
                      </Box>
                    ))}
                  </FormControl>
                )}
              </VStack>
              {currentFilters && currentFilters.length > 0 && (
                <Box>
                  <Divider marginTop={4} />
                  <Heading as="h4" fontSize="md" marginY={4}>
                    Weitere Filter:
                  </Heading>
                  <HStack>
                    {currentFilters.map((filter) => {
                      return (
                        <Tag
                          key={`${filter.field}-${filter.label}`}
                          p={0}
                          border="1px"
                          borderColor="gray.300"
                          variant="outline"
                          boxShadow="none"
                          color="gray.600"
                          // onClick={removeFilter(filter)}
                          onClick={() => {
                            setValue(filter.field as any, '');
                          }}
                        >
                          <Flex
                            height="auto"
                            alignItems="center"
                            alignSelf="stretch"
                            borderLeftRadius="md"
                            borderRight="1px"
                            borderRightColor="gray.300"
                            paddingX="2"
                            bg="gray.50"
                            paddingY="2"
                            fontWeight="bold"
                          >
                            {filterFieldToHuman(filter.field)}
                          </Flex>
                          <TagLabel paddingX="2">{filter.label}</TagLabel>
                          <TagCloseButton marginLeft={0} marginRight={2} />
                        </Tag>
                      );
                    })}
                  </HStack>
                </Box>
              )}
              {/* INFO: Disabled because we have dedicated group-search site */}
              {/*{store.config.filters?.groupSearch && (*/}
              {/*  <Box my={4}>*/}
              {/*    <Checkbox ref={register} name={`group_search`}>*/}
              {/*      Gruppensuche*/}
              {/*    </Checkbox>*/}
              {/*  </Box>*/}
              {/*)}*/}
              <Box marginTop={4} color="gray.500" fontSize="sm">
                Um nach einer <strong>Stadt</strong> oder <strong>PLZ</strong>{' '}
                zu filtern, bitte die <strong>Suche</strong> verwenden.
              </Box>
              <Divider marginY={4} />

              <Button type="submit" colorScheme="blue" size="lg" width="100%">
                Filter übernehmen
              </Button>
              <Button
                marginTop={2}
                colorScheme="red"
                variant="outline"
                width="100%"
                size="sm"
                onClick={resetFull}
              >
                Alle Filter zurücksetzen
              </Button>
            </form>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
